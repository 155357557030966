<template>
    <span>
        <v-btn
          id="saveButtonLg"
          v-if="!view || view === 'big'"
          :color="$store.getters.palette.secondary"
          dark
          @click="$emit('save')"
        >
          Salvar
        </v-btn>
    
        <v-btn
          id="saveButtonSm"
          v-if="view === 'small'"
          class="mx-2"
          fab
          dark
          small
          :color="$store.getters.palette.secondary"
          @click="$emit('save')"
        >
          <v-icon dark>
            mdi-save
          </v-icon>
        </v-btn>
    </span>
</template>

<script>
  export default {
    props: ['view']
  }
</script>
