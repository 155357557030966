const yup = require('yup')
const { setLocale } = require('yup');
const helpers = require('../addons/helpers');

const userModel = () => {
    return {
        first_name: '',
        last_name: '',
        active: true,
        email: '',
        phone: '',
        password: '',
    }
}

const contactInformationModel = () => {
    return {
        name: '',
        email: '',
        phone: '',
    }
}

const typeModel = () => {
    return {
        app: 'iron',
        scope: '',
        description: '',
        name: '',
        value: '',
        color: '',
        icon: '',
    }
}

const roleModel = () => {
    return {
        name: '',
        value: '',
    }
}

const definitionModel = () => {
    return {
        refcode: '',
        datatype: '',
        key: '',
        value: '',
    }
}

const bugModel = () => {
    return {
        link: '',
        description: '',
    }
}

const processUser = item => {
    const processed = {
        first_name: item.first_name ? helpers.name(item.first_name) : item.first_name,
        last_name: item.last_name ? helpers.name(item.last_name) : item.last_name,
        active: item.active ? true : false,
        email: item.email ? item.email.trim() : item.email,
        phone: item.phone ? item.phone.trim() : item.phone,
        password: item.password,
    }
    if (item.office) {
        processed.office = item.office
    }
    if (item.roles && item.roles.length > 0) {
        processed.roles = item.roles.map(i => { return i.name })
    }
    // if (item.definitions) {
    //     processed.definitions = item.definitions.map(processDefinition)
    // }
    return processed
}

const processContactInformation = item => {
    const processed = {
        user_id: item.user_id,
        name: item.last_name ? helpers.name(item.last_name) : item.last_name,
        email: item.email ? item.email.trim() : item.email,
        phone: item.phone ? item.phone.trim() : item.phone,
    }
    return processed
}

const processProfile = item => {
    const processed = {
        first_name: item.first_name ? helpers.name(item.first_name) : item.first_name,
        last_name: item.last_name ? helpers.name(item.last_name) : item.last_name,
        active: item.active ? true : false,
        email: item.email ? item.email.trim() : item.email,
        phone: item.phone ? item.phone.trim() : item.phone,
    }
    // if (item.definitions) {
    //     processed.definitions = item.definitions.map(processDefinition)
    // }
    return processed
}

const processType = item => {
    const processed = {
        scope: item.scope ? helpers.slug(item.scope) : '',
        app: item.app,
        description: item.description,
        name: item.name ? helpers.name(item.name) : '',
        value: item.value  ? item.value.trim() : helpers.slug(item.name),    
        color: item.color && item.color.hex ? item.color.hex : item.color,    
        icon: item.icon ? item.icon : item.icon,
    }
    return processed
}

const processRole = item => {
    const processed = {
        name: item.name ? helpers.name(item.name) : item.name,
        value: item.value  ? item.value.trim() : helpers.slug(item.name),    
    }
    return processed
}

const processToken = item => {
    const processed = {
        token: item.token,
        user: item.user,    
    }
    return processed
}

const processDefinition = item => {
    const processed = {
        refcode: item.refcode,
        datatype: item.datatype,
        key: item.key,
        value: item.value
    }
    return processed
}


const processBug = item => {
    const processed = {
        link: item.link,
        description: item.description  ? item.description.trim() : item.description,    
    }
    return processed
}

const displayDefinition = item => {
    const display = {
        refcode: item.refcode,
        key: item.key,
        value: item.value,
        datatype: item.datatype 
    }
    return display
}

const displayUserHistory = (item, dictionary) => {
    const display = {
        refcode: item.refcode,
        time: helpers.formatDatetime(item.time),
        description: helpers.fromDictionary(dictionary, item.value)
    }
    return display
}

const displayUser = (item, dictionary) => {
    const display = {
        id: item.id,
        active: item.active,
        office: item.office,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        phone: item.phone,
        password: item.password,
        timeline: item.timeline ? item.timeline.map(i => {
            return displayUserHistory(i, dictionary)
        }) : item.timeline,
        created: helpers.formatDate(item.created),
        modified: helpers.formatDate(item.modified),
    }
    if (item.roles) {
        display.roles = item.roles
    }
    // if (item.definitions) {
    //     display.definitions = item.definitions.map(displayDefinition)
    // }
    return display
}

const displayContactInformation = (item) => {
    if (!item) {
        return {}
    }
    const display = {
        refcode: item.refcode,
        user_id: item.user_id,
        name: item.name,
        email: item.email,
        phone: item.phone,
    }
    return display
}

const displayType = item => {
    const display = {
        id: item.id,
        app: item.app,
        scope: item.scope,
        description: item.description,
        name: item.name,
        value: item.value,
        color: item.color,    
        icon: item.icon,    
        created: helpers.formatDate(item.created),
        modified: helpers.formatDate(item.modified),
    }
    return display
}



const displayComment = item => {
    const display = {
        comment: item.comment,
        when: helpers.formatDate(item.created) + ' ' + helpers.timeSince(item.created),
        created: item.created,
    }
    return display
}

const displayActivity = item => {
    const display = {
        refcode: item.refcode,
        event: item.event,
        value: item.value,
        time_raw: item.time,
        time: helpers.formatDate(item.time) + ' ' + helpers.timeSince(item.time),
        description: item.description
    }
    return display
}

const displayHistory = item => {
    const display = {
        when: helpers.formatDate(item.when) + ' ' + helpers.timeSince(item.when),
        created: item.when,
    }
    if (item.old && item.new) {
        display.old = item.old
        display.new = item.new
    }
    return display
}


const displayFile = (item) => {
    let domain = 'https://iron.gyramais.com.br'
    if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        domain = 'http://localhost:5000'
    }
    const filepath = domain + '/static/uploads/' + item.filename
    const display = {
        size: item.size ? helpers.formatBytes(item.size) : item.size,
        mimetype: item.mimetype,
        title: item.title,
        filename: item.filename,
        filepath: filepath,
        uploaded_by: item.uploaded_by ? displayContactInformation(item.uploaded_by) : null,
        created: helpers.formatDate(item.created),
        modified: helpers.formatDate(item.modified),
    }
    return display
}

const displayRole = item => {
    const display = {
        id: item.id,
        name: item.name,
        value: item.value,    
        created: helpers.formatDate(item.created),
        modified: helpers.formatDate(item.modified),
    }
    return display
}

const displayToken = item => {
    const display = {
        id: item.id,
        token: item.token,
        user: displayUser(item.user),    
        created: helpers.formatDate(item.created),
        modified: helpers.formatDate(item.modified),
    }
    return display
}

const displayBug = item => {
    const display = {
        id: item.id,
        link: item.link,
        description: item.description,    
        print_screen: item.print_screen ? displayFile(item.print_screen) : item.print_screen,    
        created: helpers.formatDate(item.created),
        modified: helpers.formatDate(item.modified),
    }
    return display
}

setLocale({
  mixed: {
    default: 'Não é válido',
  },
  number: {
    default: 'Não é um número válido',
    min: 'Deve ser maior que ${min}',
  },
  string: {
    default: 'Não é um número válido',
    min: 'Deve ser maior que ${min}',
  },
})

let typeSchema = yup.object().shape({
    scope: yup.string().required(),
    app: yup.string().required(),
    description: yup.string().nullable(true),
    name: yup.string().required(),
    value: yup.string().required(),
    color: yup.string().nullable(true),
    icon: yup.string().nullable(true),
    dark: yup.bool().nullable(true),
})

let definitionSchema = yup.object().shape({
    refcode: yup.string().nullable(true),
    key: yup.string().required(),
    value: yup.string().required(),
    datatype: yup.string().required(),
})

let bugSchema = yup.object().shape({
    link: yup.string().required(),
    description: yup.string().required(),
})

let roleSchema = yup.object().shape({
    name: yup.string().required(),
    value: yup.string().required(),
})

let userSchema = yup.object().shape({
    phone: yup.string().nullable(true),
    office: yup.string().nullable(true),
    first_name: yup.string(),
    last_name: yup.string(),
    email: yup.string().required(),
    password: yup.string().nullable(true),
})

let contactInformationSchema = yup.object().shape({
    phone: yup.string().nullable(true),
    name: yup.string(),
    user_id: yup.string(),
    email: yup.string().required(),
})

let profileSchema = yup.object().shape({
    phone: yup.string().nullable(true),
    first_name: yup.string(),
    last_name: yup.string(),
    email: yup.string().required(),
})

export {
    contactInformationModel,
    userModel,
    typeModel,
    definitionModel,
    bugModel,
    roleModel,

    displayContactInformation,
    displayRole,
    displayBug,
    displayType,
    displayUser,
    displayToken,
    displayDefinition,
    displayFile,
    displayComment,
    displayActivity,
    displayHistory,


    contactInformationSchema,
    bugSchema,
    typeSchema,
    userSchema,
    profileSchema,
    roleSchema,
    definitionSchema,

    processContactInformation,
    processBug,
    processType,
    processUser,
    processProfile,
    processRole,
    processToken,
    processDefinition,
}
